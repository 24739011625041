@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@2.0.1/dist/tailwind.min.css");
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');

.cursive {
  font-family: "Amatic SC", cursive;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

.poster-color {
  background: #F7F6E2;
}

nav button {
  border: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.container1 {
  background-color: white;
  padding: 20px 40px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px #0000003b;
  min-width: 60vw;
}

label {
  margin: 10px 0px;
}

.input {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

input {
  height: 40px;
  font-size: 18px;
  border: 1px solid #e3e3e3;
  width: 100%;
}

textarea {
  resize: none;
  height: 200px;
  font-size: 18px;
  border: 1px solid #e3e3e3;
  width: 100%;
  margin-bottom: 20px;
}

input[type="submit"] {
  background-color: rgb(59, 130, 246) !important;
  border-color: rgb(59, 130, 246) !important;
  color: white !important;
}

input[type="submit"]:hover {
  background-color: white !important;
  border-color: rgb(59, 130, 246) !important;
  color: rgb(59, 130, 246) !important;
}

.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  z-index: 10000!important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.showMenuNav {
  body {
    overflow: hidden !important;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15%;
}

.deoKatalog {
  display: flex;
  gap: 30px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 2%;
  justify-content: space-around;

}

.textKatalog {
  text-align: left;
}

.textKatalog h2 {
  font-weight: bold;
  font-size: 25px;
}

.deoKatalog img {
  max-width: 100px;
}

.hidden1 {
  visibility: hidden;
  

}

.suseDeo {
  /* display: grid;
  grid-template-columns: 2fr 1fr; */
  gap: 1%;
}

.suseDeoVeci {
  border: 1px solid black;
  border-radius: 10px;
  padding: 2%;
  margin-bottom: 10px;
}

.suseDeoManji {
  border: 1px solid black;
  border-radius: 10px;
  padding: 2%;
  height: 440px;
  overflow-y: auto;
  text-align: left;
}

.suseDeoManji h2 {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.probaTaba {
  border: 1px solid black;
  margin: 1%;
  padding: 0.5%;
  border-radius: 10px;
  font-size: 25px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .taboviHeader {
    flex-wrap: wrap;
  }
  .hidden1 {
    display: none;
  }
  .wrapper {
    display: block;
    margin: 0.25rem 1.25rem;
  }  
  .deoKatalog {
    width: 100%; 
    margin-bottom: 10px; 
  }
  .hidden {
    display: none;
  }

}